<template>
    <div>
      <div v-html="apply"></div>
    </div>
  </template>
  <script>
  export default {
    data () {
      return {
        apply: ""
      }
    },
    created () {
      let form = this.$route.query.htmls
      this.apply = form
    },
    mounted () {
      setTimeout(() => {
          document.forms[0].submit()
        }, 1000)
    }
  
  }
  </script>